import React from 'react'
import { css, SerializedStyles } from '@emotion/core'
import {
  TABLET_ONLY,
  DESKTOP_ONLY,
  TABLET_OR_MORE,
  DESKTOP_OR_MORE
} from '../../../constants/theme.styles'

// TODO: Fix container by adding padding: 15px
// do this after header grid is fixed
let containerBaseStyles = css`

  display: block;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  @media ${TABLET_OR_MORE} {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media ${DESKTOP_OR_MORE} {
    padding-left: 50px;
    padding-right: 50px;
  }

  /* display: block;
  max-width: 1300px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  @media ${TABLET_ONLY} {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media ${DESKTOP_ONLY} {
    padding-left: 70px;
    padding-right: 70px;
  } */
`

interface ITextAlignmentStyles {
  left: SerializedStyles
  right: SerializedStyles
  center: SerializedStyles
  justify: SerializedStyles
}
const textAlignStyles: ITextAlignmentStyles = {
  left: css`
    text-align: left;
  `,
  right: css`
    text-align: right;
  `,
  center: css`
    text-align: center;
  `,
  justify: css`
    text-align: justify;
    hyphens: auto;
  `
}

interface IWidthStyles {
  fluid: SerializedStyles
  narrow: SerializedStyles
  veryNarrow: SerializedStyles
}
const widthStyles: IWidthStyles = {
  fluid: css`
    width: auto;
  `,
  narrow: css`
    max-width: 1120px;
  `,
  veryNarrow: css`
    max-width: 700px;
  `
}

type ContainerTagName =
  | 'div'
  | 'span'
  | 'main'
  | 'article'
  | 'section'
  | React.FC
  | React.ComponentClass
type TextAlignment = 'left' | 'right' | 'center' | 'justify'
type WidthType = 'fluid' | 'narrow' | 'veryNarrow'

interface ContainerProps {
  /** Align container text. */
  textAlign?: TextAlignment
  /** The maximum width of the container */
  width?: WidthType
  /** The element to use for the container */
  as?: ContainerTagName
  children?: React.ReactNode
}

const Container = ({
  as: Element = 'div',
  textAlign = 'left',
  width,
  children,
  ...rest
}: ContainerProps) => {
  const styles = [containerBaseStyles, width && widthStyles[width], textAlignStyles[textAlign]]
  return (
    <Element css={styles} {...rest}>
      {children}
    </Element>
  )
}

export default Container
