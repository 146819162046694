import React from 'react'
import { css } from '@emotion/core'

const textBreakStyles = css`
  display: block;
`

export const TextBreak: React.FC = ({ children }) => {
  return <span css={textBreakStyles}>{children}</span>
}
