import React from 'react'
import { css } from '@emotion/core'
import { BLUE, YELLOW, BLACK, GRAY_LIGHT } from '~/constants/theme.styles'

const hightlightContainer = css`
  position: relative;
  display: inline-block;
  :after {
    content: '';
    width: 100%;
    display: inline-block;
    position: absolute;
    bottom: 0.1em;
    left: 0;
    border-radius: 2em;
    z-index: 0;
    background-color: #8053fd;
    height: 0.25em;
  }
`
const hightlightText = css`
  position: relative;
  z-index: 1;
`

type Colors = 'purple' | 'blue' | 'yellow' | 'gray'
const colorStyles = (color: Colors) => {
  return css`
    :after {
      background-color: ${color === 'blue'
        ? BLUE
        : color === 'purple'
        ? '#a083f7'
        : color === 'yellow'
        ? YELLOW
        : color === 'gray'
        ? GRAY_LIGHT
        : BLACK};
    }
  `
}

type Props = {
  color?: Colors
}

export const HighlightText: React.FC<Props> = ({ children, color = 'purple', ...rest }) => {
  return (
    <span css={[hightlightContainer, colorStyles(color)]} {...rest}>
      <span css={hightlightText}>{children}</span>
    </span>
  )
}
